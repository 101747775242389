a.item-navegacao {
    padding: 0px;
    color: black;
    text-decoration: none;
}

a.item-navegacao:hover {
    color: black;
}

a.item-navegacao>div.Mui-selected {
    background-color: #445474 !important;
    color: white;
}

a.item-navegacao>div.Mui-selected>div>svg {
    color: white;
}

.background-style {
    background-color: #F9FAFC;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar:horizontal {
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #F9FAFC;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(200, 200, 200, 0.4);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(180, 180, 180, 0.4);
}

.drawer-header {
    margin: 10px 0px 20px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 4px;
}

.drawer-header span {
    font-size: 1.25rem;
    font-weight: 500;
}


.footerbar-calendario {
    height: 30px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: #00bfa5;
    bottom: 0;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    /* padding: 0px 8px; */
}

.footerbar-calendario a {
    color: #fff;
    margin: 0px 8px;
    text-decoration: none;
    /* transition: text-shadow .3s; */

}

.footerbar-calendario a:hover {
    color: #fff;
    text-shadow: 0.5px 0.5px 5px #000000;

}

@media(max-width: 600px){
    .footerbar-calendario{
        font-size: 10px;
        text-align: center;
    }
}
@media(max-width: 400px){
    .footerbar-calendario{
        font-size: 9px;
        text-align: center;
    }
}

.menu-lateral-calendario  {
    width: 300px;
    height: 100%; 
    padding: 0px 8px 10px
}

.container-calendario {
    padding: 0px 16px;
}


@media(min-width: 400px) {
    .menu-lateral-calendario  {
        width: 400px;
        padding: 0px 16px 10px
    }
    .container-calendario {
        padding: 0px 30px;
    }
}

@media(min-width: 600px) {
    .container-calendario {
        padding: 0px 30px;
    }
}

@media(min-width: 756px) {
    .container-calendario {
        padding: 0px 44px;
    }
}
/* @media(max-width: 768px) {

} */