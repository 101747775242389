.react-calendar__month-view__days__day {
  font-size: 10px !important;
  position: relative;
  padding: 5px !important;
  color: #000;
  background-color: #fff;
}

.react-calendar__month-view__weekdays{
  font-size: 10px !important;
  text-transform: uppercase;
  font-weight: bold;
}

.ck-editor__editable_inline {
min-height: 100px;
}

.menu-calendario{
width: 100% !important;
}

.react-calendar__tile:disabled{
  background-color: #f0f0f0;
}

.react-calendar__tile--hover {
  content: "hover";
  display: block;
  position: absolute;
  background-color: green;
  font-size: 9px;
  top: -10px;
  z-index: 10;
}

.react-calendar .react-calendar__tile--hoverStart {
  border-top-color: green;
}

.react-calendar .react-calendar__tile--hoverEnd {
  border-bottom-color: "purple";
}

.table-calendario {
  width: 100%;
}

.table-calendario tr td {
  border: 1px solid green;
  border-collapse: collapse;
  font-size: 12px;
  padding: 10px 5px;
}


.button-ano-calendario {
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.button-ano-calendario:hover {
  background-color: #efefef50;
}

.button-ano-calendario span {
  font-size: 18px;
  font-weight: 500;
}

.button-select {
  border: 1px solid#00bda599;
  background-color: #efefef99 !important;
}

 @media(min-width: 600px){
  .header-painel-calendario {
    position: sticky; 
    top: 60px; 
    z-index: 100;
  }
} 

