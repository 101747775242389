.div-list-status-acompanhamento{
    overflow-x: auto ;
    padding-left: 5px;
}

@media(max-width: 764px) {
    .div-list-status-acompanhamento {
        overflow-x: auto;
        display: -webkit-box;
    }
}

.div-status-acompanhamento {
    float: left;
    height: 50px; 
    width: 105px;
    border-radius: 3px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 5px 10px 5px;
    cursor: pointer;
    text-align: center;
}

.status-selected {
    background-color: #dedede !important;
}

.div-status-acompanhamento:hover{
    background-color: #dedede80;
}

.div-status-acompanhamento .title{
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}

.div-status-acompanhamento .sub-title{
    font-size: 10px;
    font-weight: 300;
    color: #fff;
}

.div-turnos-select-acompanhamento {
    display: none;
}

@media(max-width: 400px) {
    .div-turnos-acompanhamento{
        display: none;
    }

    .div-turnos-select-acompanhamento {
        display: block;
        margin: 20px 0px 10px;
    }
}
