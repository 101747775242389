#snackbar {
  visibility: hidden;
  min-width: 250px;
  position: fixed;
  z-index: 3;
  right: 1%;
  bottom: 30px;
  padding: 5px 10px;
  cursor: pointer;
  /* background-color: #067f6a; */
  background: linear-gradient(60deg, #09c5a6, #067f6a);
  color: #fff;
  border-radius: 2px;
  box-shadow: 2px 2px 10px #888888;
  /* box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15), 0 6px 4px -4px rgba(0, 0, 0, 0.61); */
}

#snackbar .title {
  font-size: 20px;
  font-weight: 500;
}

#snackbar .subtitle {
  font-size: 14px;
  font-weight: 200;
}

.show {
  visibility: visible !important;
  animation: fadein 1s;
  cursor: pointer;
}

@media (max-width: 900px) {
  #snackbar {
    left: 50% !important;
    bottom: 30px;
    width: 82vw;
    transform: translateX(-50%);
    padding: 8px 0 11px;
    margin-left: 0;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}
