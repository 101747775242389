.progress-loading {
    width: 100%;
    height: 8px;
    background: #ddd;
    position: absolute;
    top: 0px;
    border-radius: 4px;
    left: 0;
    overflow: hidden;
}

@keyframes anima-bar {
    0% {
        left: -35%;
        right: 100%;
        opacity: 0;
    }

    100% {
        left: 100%;
        right: -90%;
        opacity: 1;
    }
}

.progress-indicator {
    width: 50%;
    height: 100%;
    background: #1976d2;
    position: absolute;
    border-radius: 4px;
    animation: anima-bar .9s infinite;
}

.container-form {
    max-width: 390px;
}

.mt-1 {
    margin-top: 1rem
}

.msg-transition {
    transform: .1s;
}

.wrapper-login {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
}

.login-page {
    padding-top: 0;
    flex: 1;
    display: flex;
    padding: 0px 5% 0px 0%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: space-around;
}

.login-page-mobile {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: top;
    display: hidden;
}

.logo-fom-login {
    width: 100%;
    margin-bottom: 50px;
    text-align: center;
}

.form {
    background-color: #fff;
    border-radius: 2px;
    margin: 0;
    position: relative;
    box-shadow: none;
    padding: 10px 5px;
    border: 1px solid #dedede;
    border-radius: 4px;
    z-index: 1;
    max-width: 360px;
    text-align: center;
}

.form form {
    margin-top: 20px;
    box-shadow: none;
}

.login-form {
    padding: 10px 5px 0px;
}

.title-login {
    margin: 0px 0px 0px;
    font-size: 20px;
}

.login-form span {
    font-weight: 900;
    color: rgb(140, 140, 140);
    font-family: sans-serif;
}

.subtitle-login{
    margin: 0px 0px 20px;
    font-size: 11px;
}

input {
    background: #03bda320;
    border-radius: 3px;
    outline: 0;
    width: 85%;
    border: 0px;
    height: 40px;
    border: 0px;
    /* border: 0.5px solid #03bda3; */
    /* border: 1px solid #e8ebf2; */
    padding: 5px;
    font-size: 14px;
    box-sizing: border-box;
}

input[type="password"] {
    margin-top: 10px
}

button {
    margin-top: 10px;
    border-radius: 4px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    outline: 0;
    width: 100%;
    border: 0;
    padding: 15px;
    font-size: 14px;
}

.bt-enabled:hover {
    background: #41659b;
}

.wrapper-login footer {
    height: 60px;
    background: #077f6a;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
}
.img-logomarca-pc{
    /* position: absolute; */
    width: 40vw;
    /* right: 10%; */
}

.img-logomarca-mobile{
    display: none;
}

.text-error {
    color: #ff4455;
    display: block;
    text-align: left;
}

@media(max-width: 1050px) {
    .img-logomarca-pc{
        width: 39%;
    }
}
@media(max-width: 768px) {
    .login-page {
        background-size: 0;
        /* background-image: url('/imgs/logo_sem_fundo.png'); */
    }
    .wrapper-login{
        background-size: auto;
        background-color: #03bda2;
    }
    .img-logomarca-pc{
        display: none;
    }
    .img-logomarca-mobile{
        display: block;
        position: absolute;
        top: 7vh;
        margin: auto;
        max-width: 360px;
    }
    .login-page {
        padding: 0;
        margin:0 auto;
    }


}